export default [
  // {
  //   key: 'image',
  //   initKey: 'profilePicture',
  //   label: 'field.image',
  //   type: 'async-single-image',
  //   rules: '',
  //   pw: 200,
  //   ph: 200,
  //   fullWidth: false,
  //   cols: 12,
  // },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required|min:9|max:10',
    type: 'tel',
  },
  {
    key: 'gender',
    label: 'field.gender',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.male', value: 'male' },
      { text: 'field.female', value: 'female' },
    ],
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
]
